@use '~/src/sass/abstracts/utils' as *;
@use '~/src/sass/abstracts/variables/colors';
@use '~/src/sass/abstracts/variables/structure';
@use '~/src/sass/abstracts/variables/typography' as typo;

.textillus__wrapper {
	display: grid;
	grid-template:
		'image'
		'text';
	grid-gap: 2rem;
	color: colors.$grey-400;
}

.textillus__image {
	grid-area: image;

	img,
	svg {
		max-width: 100%;
		border-radius: 2rem;
	}
}

.textillus__text {
	grid-area: text;

	h2 {
		margin-bottom: 2rem;
	}
}

.textillus__title {
	@extend %font-xl;

	margin-bottom: 4rem;
	color: colors.$orange;
}

@media screen and (min-width: structure.$tablet-landscape) {
	.textillus__wrapper {
		grid-template: 'text image';
		grid-gap: 3rem;

		&.textillus--align-top {
			align-items: start;
		}

		&.textillus--align-center {
			align-items: center;
		}

		&.textillus--align-bottom {
			align-items: end;
		}

		&.textillus--text-on-img {
			grid-template-columns: minmax(1rem, 4fr) minmax(1rem, 6fr);
			grid-gap: 0;

			.textillus__text {
				z-index: 2;
				transform: translateX(50%);
			}
		}

		&.textillus--full-width {
			max-width: 100%;
			padding: 0;

			.textillus__text {
				padding-left: 2rem;
				direction: rtl;
			}

			&.textillus--right {
				.textillus__text {
					padding-right: 2rem;
					padding-left: 0;
					direction: ltr;
				}
			}
		}

		&.textillus--extra-small {
			max-width: 116rem;
		}

		&.textillus--is-column {
			grid-template:
				'text'
				'image';
			grid-gap: 0;
			text-align: center;

			svg,
			img {
				margin: auto;
			}
		}

		&.textillus--right {
			grid-template: 'image text';

			&.textillus--text-on-img {
				grid-template-columns: minmax(1rem, 6fr) minmax(1rem, 4fr);

				.textillus__text {
					transform: translateX(-50%);
				}
			}
		}

		&.textillus--50-50 {
			grid-template-columns: repeat(2, minmax(1rem, 1fr));
		}

		&.textillus--25-75 {
			grid-template-columns: minmax(1rem, 3fr) minmax(1rem, 6fr);
		}

		&.textillus--75-25 {
			grid-template-columns: minmax(1rem, 6fr) minmax(1rem, 3fr);
		}

		&.textillus--7-8 {
			grid-template-columns: minmax(1rem, 7fr) minmax(1rem, 8fr);
		}

		&.textillus--8-7 {
			grid-template-columns: minmax(1rem, 8fr) minmax(1rem, 7fr);
		}
	}
}
